<template>
  <div id="OtherExpenses">
    <CSBreadcrumb/>
    <div class="result-panel">
        <CSTable>
            <template v-slot:thead>
                <tr>
                    <th>类目</th>
                    <th>实际应付金额</th>
                </tr>
            </template>
            <template v-slot:tbody>
                <tr
                    v-for="charge in chargeList"
                    :key="charge.name"
                >
                    <td>{{charge.name}}</td>
                    <td>{{charge.amount}}元</td>
                </tr>
            </template>
        </CSTable>
    </div>
  </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import {

} from "@/requestUrl"
import CSTable from "@/components/common/CSTable";
export default {
  name: "OtherExpenses",
  props: {
    charges: {
        type: String,
        default: '[]'
    }
  },
  components: {
      CSTable,
    CSBreadcrumb,
  },
  data() {
    return {
      chargeList: JSON.parse(this.charges),
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
